@import '../../styles/mixins.scss';

.movie-page .movie {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;

  p {
    margin: 10px 0;
    line-height: 1.5;
  }

  &__poster {
    position: relative;
  }

  &__save {
    @include transparent-text();
    border-radius: 0 var(--border-radius) 0 0;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 10px;
  }

  &__image {
    border-radius: var(--border-radius);
  }

  &__facts {
    & > span + span:before {
      content: ' | ';
    }
  }

  &__tagline {
    font-style: italic;
  }

  &__details {
    max-width: 700px;
  }

  &__sinopse {
    margin: 8px 0;
  }

  &__genre {
    display: inline-block;

    &:not(:last-of-type) {
      &:after {
        content: ',';
        margin-right: 5px;
      }
    }
  }

  &__buttons {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 10px;

    a {
      height: 20px;
    }
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 40% 55%;
  }

  @media (min-width: 1024px) {
    gap: 50px;
  }
}
