@import './index.scss';

:root {
  --header-heigth: 70px;
  --border-radius: 8px;
  --color-yellow: #f7d354;
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1110px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 0 auto;
  margin: 30px 0;
}

.footer {
  margin-top: auto;
}

.favorites .movie-item__action {
  display: block;
}

@media (min-width: 768px) {
  .main {
    margin: 40px 0;
  }
}
