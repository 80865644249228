.not-found {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 70vh;
  }

  &__title {
    font-size: 120px;
    margin: 0;
  }

  h2 {
    margin: 0 0 20px;
  }
}
