.search-form {
  display: flex;

  &__input {
    border-radius: 20px 0 0 20px;
    padding: 8px 12px;
    outline: none;
    width: 100px;

    font-size: 12px;
  }

  &__button {
    background-color: var(--color-yellow);
    border: 2px solid var(--color-yellow);
    color: #000;
    padding: 8px;
    border-radius: 0 20px 20px 0;

    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    &__input {
      width: auto;
    }
  }
}
