.modal {
  position: relative;
  z-index: 100;
  visibility: hidden;

  &--open {
    visibility: visible;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    inset: 0;
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
