.menu {
  opacity: 0;
  visibility: hidden;
  display: none;
  background-color: #000;
  // transition: 0.3s;

  &__list {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__item--active .menu__link,
  &__item:hover .menu__link {
    color: var(--color-yellow);
  }

  &__link {
    display: inline-block;
    color: #fff;
    text-align: center;
    padding: 10px;
  }

  @media (min-width: 1024px) {
    opacity: 1;
    visibility: visible;
    display: block;

    &__list {
      flex-direction: row;
    }
  }
}

.menu--active {
  // transform: translate(0);
  opacity: 1;
  visibility: visible;
  display: block;
  width: 100%;
  position: absolute;
  top: var(--header-heigth);
  left: 0;
  z-index: 100;
}
