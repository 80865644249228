.header {
  width: 100%;
  background-color: black;
  position: relative;

  &__container {
    height: var(--header-heigth);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    color: white;
    text-decoration: none;
  }

  &__logo {
    font-size: 22px;
    font-weight: 700;
  }

  @media (min-width: 769px) {
    &__logo {
      font-size: 30px;
    }
  }
}
