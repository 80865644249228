html,
body {
  margin: 0;
  height: 100%;
}

body,
input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

h2 {
  margin: 0;
}
