@import '../../styles/mixins.scss';

.collection {
  position: relative;
  margin: 50px 0;
  height: 400px;

  border-radius: var(--border-radius);
  background-blend-mode: color;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: top center;

  &__infos {
    @include transparent-text();
    @include bottom-positioned();

    padding: 30px;
  }

  &__title {
    color: #fff;
  }

  &__paragraph {
    margin-top: 15px;
    max-width: 750px;
    line-height: 1.5;
  }
}
