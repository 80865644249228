@import '../../styles/mixins.scss';

.movie-item {
  background-color: white;
  border-radius: var(--border-radius);
  position: relative;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.03);
  }

  &__image {
    object-fit: cover;
    border-radius: var(--border-radius);
  }

  &__title {
    @include transparent-text();
    @include bottom-positioned();

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 15px;
    height: 40px;

    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    text-decoration: none;
  }

  &__action {
    display: none;
    position: absolute;
    right: 0;
    border-top-right-radius: var(--border-radius);
    padding: 5px;
  }
}
